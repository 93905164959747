<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="班级名称:">
            <el-input 
							v-model.trim="searchForm.classname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="班级名称" prop="classname" align="center" />

      <el-table-column label="操作" align="center" width="200" fixed="right">
        <template slot-scope="scope" align="center">
          <el-button
            type="primary"
            size="mini"
            @click="onShowStudents(scope.row)"
            >查看学员</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
			:close-on-click-modal='false'
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>{{ dialogTitle }}</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="学院代码:">
          {{ dynamicValidateForm.code }}
        </el-form-item>
        <el-form-item label="院校名称:">
          {{ dynamicValidateForm.name }}
        </el-form-item>
        <el-form-item label="LOGO:">
          <img
            :src="$url.upload + dynamicValidateForm.logourl"
            alt=""
            class="picture"
          />
        </el-form-item>
        <el-form-item label="可用标志:">
          {{ enableflag[dynamicValidateForm.enableflag] }}
        </el-form-item>
        <el-form-item label="排序权重:">
          {{ dynamicValidateForm.rank }}
        </el-form-item>
        <el-form-item label="院校简介:">
          {{ dynamicValidateForm.brief }}
        </el-form-item>

        <table
          border="1"
          cellpadding="10"
          cellspacing="0"
          v-if="dynamicValidateForm.list.length"
        >
          <tr>
            <td>层次</td>
            <td>招生专业</td>
            <td>学制</td>
          </tr>
          <tr v-for="(item, i) in dynamicValidateForm.list" :key="i">
            <td>{{ item.majorlevelval }}</td>
            <td>{{ item.collegename }}</td>
            <td>{{ item.xuezhi }}</td>
          </tr>
        </table>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="600px"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
        label-width="140px"
      >
        <el-form-item label="班级名称:" prop="classname">
          <el-input v-model="dynamicValidateForm.classname" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 查看学员 -->
    <el-dialog
      class="btn-2b5a95"
      width="1000px"
			v-dialogDrag
			:close-on-click-modal='false'
      title="查看学员"
      :visible.sync="showStudentList"
      v-if="showStudentList"
      append-to-body
    >
      <el-table
        :data="studentsRes.list"
        stripe
        border
        ref="multipleTable"
        style="width: 100%"
        height="250"
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="学生姓名" prop="studentname" align="center" />
        <el-table-column
          label="辅导班班别名称"
          prop="fudaoclasstypename"
          align="center"
          width="250px"
        />
        <el-table-column
          label="班别类型名称"
          prop="fudaotypeval"
          align="center"
					width="180px"
        />
        <el-table-column label="报读时间" prop="regdate" align="center" width="160">
          <template slot-scope="scope">
            {{ scope.row.regdate | formatDate("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="跟进人姓名" prop="followusername" align="center" />
        <el-table-column label="教学点" prop="schoolorgname" align="center" />
      </el-table>
       <el-pagination
      background
      @current-change="handleStudentsChange"
      :current-page="studentsPageInfo.pageindex"
      :page-size="studentsPageInfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="studentsRes.totalcount"
    ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import Import from "@/components/Import/index.vue";

import {
  techclass_list,
  techclass_save,
  techclass_delete,
  techclass_list2,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _pictureupload_save } from "@/request/api/public";
import {
  _collegetype,
  _availables,
  _projectTypes,
  _available,
  _projectType,
} from "@/assets/js/filedValueFlag";

export default {
  mixins: [part],
  name: "college",
  components: { Export, Import },
  props: {
    availables: {
      default: () => _availables,
    },
    projectTypes: {
      default: () => _projectTypes,
    },
    available: {
      default: () => _available,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, // 添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        code: [{ trigger: "blur", message: "必填字段", required: true }],
        name: [{ trigger: "blur", message: "必填字段", required: true }],
        projecttypekey: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        fudaotypekey: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        istotal: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),
      studentsRes: {}, //学员数据
      showStudentList: false,
      studentsPageInfo:{
        pageindex: 1,
        pagesize: 10,
      }
    };
  },
  created() {
    this.getDataList();
    this.dic_combox({
      that: this,
      list: "techclasstypeList",
      typecode: "techclasstype",
    });
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["dic_combox"]),
		reset() {
			this.searchForm = {
				classname: null
			}
		},
		resetQuery(){
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: techclass_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: techclass_list2,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = res.data.data;
        }
      });
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {};
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnRefresh() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: fudaoclasstype_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.dynamicValidateForm = res.data.data.vfudaoclasstype;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: techclass_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: techclass_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //查看学员 click
    onShowStudents(row) {
      this.itemId = row.id
      myRequest({
        url: techclass_list2,
        data: {
          techclassid: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.studentsRes = res.data.data;
          this.showStudentList = true;
        }
      });
    },
    //切换学员分页 change
    handleStudentsChange(val){
      this.studentsPageInfo.pageindex = val;
      this.onShowStudents(this.itemId);
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style  lang="scss">
</style>